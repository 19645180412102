import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SystemEvent as SystemEventEnum } from 'app/enums/global/system-event.enum';
import { FeatureManagerService } from '@Services';
import * as _ from 'lodash';
import { pairwise } from 'rxjs/operators';
import { AppService } from 'app';
import { SystemEventAction, SystemEventActionType } from '../../../enums/global/system-event-actions.enum';
import { Account, AccountEvent, SystemEvent } from '@Models';
import { AccountEventsService, ConfirmationDialogService, NotificationService, NotificationSeverity, SignerService } from '../../../services';
import { EventActionAccordComponent } from './actions/event-action-accord/event-action-accord.component';
import { EventDisplayMessageActionComponent } from './actions/event-action-display-message/event-action-display-message.component';
import { EventEmailActionComponent } from './actions/event-action-email/event-action-email.component';
import { EventActionFaxComponent } from './actions/event-action-fax/event-action-fax.component';
import { EventActionGTLSubmissionComponent } from './actions/event-action-gtl-submission/event-action-gtl-submission.component';
import { EventIntegrationActionComponent } from './actions/event-action-integration/event-action-integration.component';
import { EventSMSActionComponent } from './actions/event-action-sms/event-action-sms.component';
import { EventActionWebhookComponent } from './actions/event-action-webhook/event-action-webhook.component';
import { FeatureToggle } from '@Enums';
import { ConfigDataDTO } from '@DTOs';

@Component({
    selector: 'account-events',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar bg-white' },
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules;
    @Input() mapKeys;
    @Input() nonListMapKeys;
    public eventForm: UntypedFormGroup;
    events: AccountEvent[];
    eventTypes: SystemEvent[];
    eventTypesSansCaseStatusV2: SystemEvent[];
    eventConditions: any;
    actionConditions: any;
    toggleConditions: any;
    actions: any;
    filteredActions: any;
    systemEventActionFilters: any;
    selectedEvent: AccountEvent;
    selectedAction: any;
    configData: ConfigDataDTO;
    orderApiCategory: boolean;
    noteAddedEvent: boolean;
    eventConditionLabel: any;
    multipleEventConditions: boolean;
    actionConditionLabel: any;
    toggleConditionLabel: any;
    formSubmitted = false;
    emailDialogRef: MatDialogRef<EventEmailActionComponent>;
    displayMessageDialogRef: MatDialogRef<EventDisplayMessageActionComponent>;
    displayIntegrationDialogRef: MatDialogRef<EventIntegrationActionComponent>;
    displaySmsDialogRef: MatDialogRef<EventSMSActionComponent>;
    displayWebhookDialogRef: MatDialogRef<EventActionWebhookComponent>;
    displayGTLDialogRef: MatDialogRef<EventActionGTLSubmissionComponent>;
    displayAccordDialogRef: MatDialogRef<EventActionAccordComponent>;
    displayFaxDialogRef: MatDialogRef<EventActionFaxComponent>;
    enableCaseStatusV2: boolean;

    constructor(
        public dialog: MatDialog,
        private appService: AppService,
        private accountEventsService: AccountEventsService,
        private confirmationService: ConfirmationDialogService,
        private notificationService: NotificationService,
        private signerService: SignerService,
        private _fb: UntypedFormBuilder,
        private featureManagerService: FeatureManagerService
    ) {
    }

    ngOnInit() {
        this.eventForm = this._fb.group({
            id: [''],
            isActive: [''],
            systemEventActionId: ['', Validators.required],
            name: ['', Validators.required],
            systemEventId: ['', Validators.required],
            ruleId: [''],
        });

        this.enableCaseStatusV2 = this.featureManagerService.getByName(FeatureToggle.SystemEventEnableCaseStatusV2).enabled;

        this.appService.getAllConfigData().then(configDataDTO => {
            this.configData = configDataDTO;
            this.eventTypes = this.configData.systemEvents;

            if (this.configData.systemEvents) {
                this.eventTypesSansCaseStatusV2 = this.configData.systemEvents.filter(x => x.id !== SystemEventEnum.CaseStatusV2);
            } else {
                this.eventTypesSansCaseStatusV2 = this.configData.systemEvents;
            }

            this.actions = this.filterSystemEventActions(this.configData.systemEventActions);
            this.systemEventActionFilters = this.configData.systemEventActionFilters;
        });

        this.orderApiCategory = this.featureManagerService.getByName('Case.Event.OrderApi.Category').enabled;
        this.noteAddedEvent = this.featureManagerService.getByName('Global.Events.NoteAdded').enabled;

        this.eventForm.get('systemEventId').valueChanges.pipe(pairwise())
            .subscribe(([prev, next]: [any, any]) => {
                if (prev !== next) {
                    if (next) {
                        this.selectedEvent.systemEventId = next;
                        if (!this.selectedEvent.id || this.selectedEvent.id === null) {
                            this.selectedEvent.name = this.eventForm.controls['name'].value;
                        }

                    }
                    this.getEventParams(next);
                    this.filterEventActions();
                }
            });
        this.eventForm.get('systemEventActionId').valueChanges.pipe(pairwise())
            .subscribe(([prev, next]: [any, any]) => {
                if (prev !== next) {
                    if (next) {
                        this.selectedEvent.systemEventActionId = next;

                        if (!this.selectedEvent.id || this.selectedEvent.id === null) {
                            this.selectedEvent.name = this.eventForm.controls['name'].value;
                        }
                    }
                    this.selectedAction = next;
                    this.getActionParams(next);
                }
            });

        this.getAccountEvents();
    }

    get SystemEventEnum() { return SystemEventEnum; }

    getAccountEvents() {
        this.accountEventsService.getAccountEvents(this.accountInfo.id).subscribe(accountEvents => {
            if (!this.noteAddedEvent) {
                accountEvents = accountEvents.filter(obj => obj.name !== 'Note Added');
            }

            this.events = _.sortBy(accountEvents, ['name']);
        });
    }

    getEventParams(option: any) {
        if (!this.toggleConditions
            || this.toggleConditions.name !== "regenerateDocuments"
            || !this.selectedEvent
            || this.selectedEvent.systemEventActionId !== SystemEventAction.MarkCaseDone) {
            this.toggleConditions = null;
        }
        if (!option) {
            return false;
        }
        const eventType = _.find(this.eventTypes, { 'id': option });
        if (eventType.params) {
            const params = JSON.parse(eventType.params);
            params.forEach(param => {
                if (param.paramType === 'lookup' && param.lookupEnum) {
                    this.eventConditions = this.configData[param.lookupEnum];

                    this.setupConditions(param);
                } else if (param.paramType === 'configLookup' && param.lookupEnum === 'accountSigner') {
                    this.signerService.getAccountSigners(this.accountInfo.id).subscribe(data => {
                        data.forEach(x => {
                            x.name = x.label;
                        });

                        this.eventConditions = data;
                        this.setupConditions(param);
                    });
                } else if (param.paramType === 'rawJson') {
                    this.eventConditions = params;

                    let paramJson = _.find(params, { 'code': this.selectedEvent.conditions });
                    if (param.checkFeatureToggle) {
                        if (param.checkFeatureToggle == "OrderApiCategory") {
                            if (!this.orderApiCategory) {
                                this.selectedEvent.conditions = null;
                                this.eventConditions = null;
                                return;
                            }
                        }
                    }

                    if (!paramJson) {
                        paramJson = param;
                    } else {
                        paramJson.paramType = param.paramType;
                        paramJson.label = param.label;
                    }
                    this.setupConditions(paramJson);
                } else if (param.paramType === 'toggle') {
                    this.toggleConditions = param;
                    this.setupToggleConditions(param);
                } else if (param.paramType === 'multi-lookup') {
                    this.eventConditions = this.configData[param.lookupEnum];
                    this.setupConditions(param);
                }
            });
        } else {
            this.eventConditions = null;
            if (this.eventForm.get('conditionId')) {
                this.eventForm.removeControl('conditionId');
            }
        }
    }

    getActionParams(option: any) {
        if (!option) {
            return false;
        }

        if (option !== SystemEventAction.MarkCaseDone && this.toggleConditions !== null && this.toggleConditions.name === "regenerateDocuments") {
            this.toggleConditions = null;
        }

        const actionType = _.find(this.actions, { 'id': option });
        if (actionType.params) {
            const params = JSON.parse(actionType.params);
            if (params.length > 0) {
                const param = params[0];
                if (param.paramType === 'lookup' && param.lookupEnum) {
                    this.actionConditions = this.configData[param.lookupEnum];

                    this.setupActionConditions(param);
                } else if (param.paramType === 'configLookup' && param.lookupEnum === 'accountSigner') {
                    this.signerService.getAccountSigners(this.accountInfo.id).subscribe(data => {
                        data.forEach(x => {
                            x.name = x.label;
                        });

                        this.actionConditions = data;
                        this.setupActionConditions(param);
                    });
                } else if (param.paramType === 'rawJson') {
                    this.actionConditions = params;
                    this.setupActionConditions(param);
                } else if (param.paramType === 'toggle') {
                    this.toggleConditions = param;
                    this.setupToggleConditions(param);
                }
            }
        } else {
            this.actionConditions = null;
            if (this.eventForm.get('actionConditionId')) {
                this.eventForm.removeControl('actionConditionId');
            }
        }
    }

    filterSystemEventActions(systemEventActions: any) {
        if (!systemEventActions)
            return [];

        const skipFilteringClientIds = ['7ff6fc11-3cd8-4f81-9498-799b7983a87d', 'eae69d02-3b32-4a7b-b18d-0a137efb9306'];
        const filteredEvents = systemEventActions.filter(action =>
            skipFilteringClientIds.includes(this.accountInfo.clientId) ||
            (
                !Object.prototype.hasOwnProperty.call(action, 'clientId') ||
                action.clientId === this.accountInfo.clientId
            )
        );

        return filteredEvents;
    }

    private setupConditions(param: any) {
        this.eventConditionLabel = param.label;

        if (!this.eventForm.get('conditionId')) {
            this.eventForm.addControl('conditionId', new UntypedFormControl('', Validators.required));
        }

        // Set the control property based on paramtype
        if (param.paramType === 'multi-lookup') {
            this.multipleEventConditions = true;
        } else {
            this.multipleEventConditions = false;
        }

        if (this.selectedEvent != null && this.selectedEvent.conditions) {
            const conditions = JSON.parse(this.selectedEvent.conditions);
            if (param.paramType === 'multi-lookup') {

                this.selectedEvent.conditionId = conditions['caseStatusV2'].split(',');
                this.eventForm.patchValue(this.selectedEvent);

            } else if (param.paramType != 'rawJson') {

                this.selectedEvent.conditionId = conditions[param.lookupEnum];
                this.eventForm.patchValue(this.selectedEvent);

            } else {

                this.selectedEvent.conditionId = param.id;
                this.eventForm.patchValue(this.selectedEvent);

            }
        }
    }

    private setupActionConditions(param: any) {
        this.actionConditionLabel = param.label;

        if (!this.eventForm.get('actionConditionId')) {
            this.eventForm.addControl('actionConditionId', new UntypedFormControl('', Validators.required));
        }
        if (this.selectedEvent != null && this.actionConditions && this.selectedEvent.actionConditionId) {
            this.eventForm.patchValue(this.selectedEvent);
        }
    }

    private setupToggleConditions(param: any) {
        this.toggleConditionLabel = param.label;

        if (!this.eventForm.get(param.name)) {
            this.eventForm.addControl(param.name, new UntypedFormControl(''));
            this.eventForm.controls[param.name].patchValue(false);
        }

        if (this.selectedEvent != null && this.selectedEvent.conditions) {
            const conditions = JSON.parse(this.selectedEvent.conditions);
            if (conditions[param.name]) {
                this.eventForm.controls[param.name].patchValue(conditions[param.name]);
            }
        }
    }

    eventActiveToggled(event) {
        event.isActive = !event.isActive;
        this.saveEditAccount(event);
    }

    selectEvent(event: AccountEvent) {
        if (this.selectedEvent === event) {
            this.selectedEvent = null;
            this.eventConditions = null;
        } else {
            this.eventConditions = null;
            this.formSubmitted = false;
            this.eventForm.reset();
            this.selectedEvent = event;
            if (this.selectedEvent.systemEventActionId === SystemEventAction.StatusChange) {
                this.selectedEvent.actionConditionId = JSON.parse(this.selectedEvent.configuration).statusId;
            }
            if (this.selectedEvent.systemEventActionId === SystemEventAction.MarkCaseDone) {
                if (!this.selectedEvent.configuration) {
                    this.selectedEvent.regenerateDocuments = false;

                } else {
                    this.selectedEvent.regenerateDocuments = JSON.parse(this.selectedEvent.configuration).regenerateDocuments;
                }
            }
            this.eventForm.patchValue(this.selectedEvent);
        }
    }

    editAction(event) {
        const systemEventActionId = this.eventForm.controls['systemEventActionId'].value;

        if (systemEventActionId === SystemEventAction.Email) {
            this.emailDialogRef = this.dialog.open(EventEmailActionComponent, {
                width: '50%',
                data: {
                    accountId: this.accountInfo.id,
                    accountEvent: event,
                    clientId: this.accountInfo.clientId
                }
            });
        } else if (systemEventActionId === SystemEventAction.DisplayMessage) {
            this.displayMessageDialogRef = this.dialog.open(EventDisplayMessageActionComponent, {
                width: '50%',
                height: '450px',
                data: {
                    event: event,
                    mapKeys: this.mapKeys
                }
            });
        } else if (systemEventActionId === SystemEventAction.Integration) {
            this.displayIntegrationDialogRef = this.dialog.open(EventIntegrationActionComponent, {
                width: '50%',
                data: {
                    event: event,
                    mapKeys: this.mapKeys,
                    accountInfo: this.accountInfo
                },

            });
        } else if (systemEventActionId === SystemEventAction.SendSMS) {
            this.displaySmsDialogRef = this.dialog.open(EventSMSActionComponent, {
                width: '50%',
                data: {
                    event: event,
                    mapKeys: this.mapKeys,
                    accountInfo: this.accountInfo
                },

            });
        } else if (systemEventActionId === SystemEventAction.Webhook || systemEventActionId === SystemEventAction.ArcusSubmission) {
            this.displayWebhookDialogRef = this.dialog.open(EventActionWebhookComponent, {
                width: '50%',
                data: {
                    event: event,
                    accountInfo: this.accountInfo,
                    mapkeys: this.mapKeys
                },

            });
        } else if (systemEventActionId === SystemEventAction.GTLSubmission) {
            this.displayGTLDialogRef = this.dialog.open(EventActionGTLSubmissionComponent, {
                width: '50%',
                data: {
                    event: event,
                    accountInfo: this.accountInfo,
                    mapKeys: this.mapKeys,
                    nonListMapKeys: this.nonListMapKeys,
                },

            });
        } else if (systemEventActionId === SystemEventAction.AcordEvent) {
            this.displayAccordDialogRef = this.dialog.open(EventActionAccordComponent, {
                width: '50%',
                data: {
                    event: event,
                    accountInfo: this.accountInfo
                },

            });
        } else if (systemEventActionId === SystemEventAction.Fax) {
            this.displayFaxDialogRef = this.dialog.open(EventActionFaxComponent, {
                width: '50%',
                data: {
                    event: event,
                    accountInfo: this.accountInfo
                },

            });
        }
    }

    cancelEvent(accountEvent: AccountEvent) {
        if (accountEvent.id == null) {
            this.events = _.reject(this.events, function (o) { return o === accountEvent; });
        }
        this.selectedEvent = null;
    }

    addAccountEvent() {
        this.formSubmitted = false;
        const accountEvent: AccountEvent = {
            accountId: '',
            isActive: true,
            name: '',
            conditions: '',
            systemEventId: '',
            systemEventActionId: '',
            ruleId: '',
            conditionId: '',
        };
        this.eventConditions = null;
        this.selectEvent(accountEvent);
        this.selectedEvent.regenerateDocuments = false;
        if (this.events) {
            this.events.push(accountEvent);
        } else {
            this.events = [];
            this.events.push(accountEvent);
        }
    }

    saveAccountEvent() {
        if (this.eventForm.controls.systemEventId.value === SystemEventEnum.DeliverRemoteSigningMessage
            && (this.eventConditions === null || this.eventConditions.length <= 0)) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: 'Set up a Document Account Signer on the Settings tab before trying to add a Remote Signing Message',
                log: false
            });

            return false;
        }

        this.formSubmitted = true;
        if (!this.eventForm.valid) {
            this.eventForm.markAsTouched();
            this.eventForm.controls.name.markAsTouched();
            this.eventForm.controls.systemEventId.markAsTouched();
            if (this.eventForm.controls.conditionId) {
                this.eventForm.controls.conditionId.markAsTouched();
            }
            if (this.eventForm.controls.actionConditionId) {
                this.eventForm.controls.actionConditionId.markAsTouched();
            }
            if (this.eventForm.controls.systemEventActionId) {
                this.eventForm.controls.systemEventActionId.markAsTouched();
            }
            return false;
        }

        if (this.eventForm.controls.systemEventActionId.value !== SystemEventAction.Integration
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.MarkCaseDone
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.ProcessInterview
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.ProcessInterviewAccumulateErrors
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.Fax
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.StatusChange
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.EvaluateCaseManager
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.LongLivedIntegrationStatusUpdate
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.LongLivedEvidenceReceived
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.StandardIntegrationStatusChange
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.InitializeWorkbenchRequirements
            && this.eventForm.controls.systemEventActionId.value !== SystemEventAction.EvaluateUwwRequirementRules) {
            if (!this.selectedEvent.accountMessage) {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Please add action properties...', log: false });
                return false;
            }
        }

        if (this.eventForm.controls.systemEventActionId.value === SystemEventAction.StatusChange) {
            this.selectedEvent.configuration = JSON.stringify({ 'statusId': this.eventForm.get('actionConditionId').value });
        }

        if (this.eventForm.controls.systemEventActionId.value === SystemEventAction.Fax) {
            if (!this.selectedEvent.configuration || this.selectedEvent.configuration === null) {
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Error,
                    message: 'Please add action properties...',
                    log: false
                });

                return false;
            }
        }

        if (this.eventForm.controls.systemEventActionId.value === SystemEventAction.MarkCaseDone) {
            this.selectedEvent.configuration = JSON.stringify({ 'regenerateDocuments': this.eventForm.get('regenerateDocuments').value });
        }

        const accountEvent: AccountEvent = this.eventForm.value;
        accountEvent.accountId = this.accountInfo.id;
        accountEvent.accountMessage = this.selectedEvent.accountMessage;
        accountEvent.configuration = this.selectedEvent.configuration;

        if (accountEvent.systemEventActionId === SystemEventAction.SendSMS) {
            accountEvent.accountMessage.type = SystemEventActionType.SMS;
        }

        this.setConditions(accountEvent);

        if (this.selectedEvent.id) {
            if (this.eventForm.valid) {
                this.saveEditAccount(accountEvent);
                this.formSubmitted = false;
            }
        } else {
            if (this.eventForm.valid) {
                this.saveAddAccount(accountEvent);
                this.formSubmitted = false;
            }
        }
    }

    setConditions(accountEvent: AccountEvent) {
        const eventType = _.find(this.eventTypes, { 'id': accountEvent.systemEventId });

        if (eventType.params) {
            const params = JSON.parse(eventType.params);
            const conditions: any = {};
            params.forEach(param => {
                if (param.lookupEnum && param.paramType !== 'multi-lookup') {
                    conditions[param.lookupEnum] = accountEvent.conditionId;
                }
                else if (param.paramType === 'rawJson') {
                    const selectedParam = _.find(params, { 'id': accountEvent.conditionId });
                    // Because there is a feature flag on Order selection for the process interview event,
                    // when the feature flag is disabled and an account was previously configured to use order while the feature flag was
                    // enabled. Now the feature flag is disabled there is no order to be selected.
                    if (selectedParam !== undefined) {
                        accountEvent.conditions = selectedParam.code;
                    }

                } else if (param.paramType === 'toggle') {
                    conditions[param.name] = accountEvent[param.name];
                } else if (param.paramType === 'multi-lookup') {
                    // When paramType is multi-lookup the conditionId should be an array.
                    if (Array.isArray(accountEvent.conditionId)) {
                        conditions['caseStatusV2'] = accountEvent.conditionId.join(',');
                    } else {
                        conditions['caseStatusV2'] = `${accountEvent.conditionId}`;
                    }
                }
            });

            if (!_.isEmpty(conditions)) {
                accountEvent.conditions = JSON.stringify(conditions);
            }
        }
    }

    saveEditAccount(accountEvent: AccountEvent) {
        this.accountEventsService.editAccountEvent(accountEvent).subscribe(_res => {
            this.appService.showMsg('success', 'Edited successfully ...');
            this.getAccountEvents();
            this.selectedEvent = null;
        });
    }

    saveAddAccount(accountEvent: AccountEvent) {
        accountEvent.isActive = true;
        this.accountEventsService.addAccountEvent(accountEvent).subscribe(_res => {
            this.appService.showMsg('success', 'Added successfully ...');
            this.getAccountEvents();
            this.selectedEvent = null;
        });
    }

    deleteAccountEvent(accountEvent: AccountEvent) {
        this.confirmationService.open({
            message: `Are you sure that you want to delete this account event?`,
            showCancel: true,
            onOk: () => {
                this.accountEventsService.deleteAccountEvent(accountEvent).subscribe(
                    _data => {
                        this.appService.showMsg('success', 'Deleted successfully ...');
                        this.getAccountEvents();
                        this.selectedEvent = null;
                    }
                );
            }
        });
    }

    filterEventActions() {
        const actionFilters = this.systemEventActionFilters.filter(x => x.systemEventId == this.eventForm.controls['systemEventId'].value);
        this.filteredActions = this.actions;

        if (actionFilters && actionFilters.length > 0) {
            const filterIds = actionFilters.map(a => a.systemEventActionId);
            this.filteredActions = this.actions.filter((action) => filterIds.includes(action.id));
            const exists = this.filteredActions.filter(x => x.id == this.selectedEvent.systemEventActionId);
            if (!(exists && exists.length > 0)) {
                this.selectedEvent.systemEventActionId = null;
                this.eventForm.patchValue(this.selectedEvent);
            }
        }
    }

}
